import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import "./index.scss";

// components
import Loader from "components/common/loadingPage";
import Navbar from "components/common/navbar";
import Modal from "components/work/modal";

// assets
import Fetefully from "assets/work/fetefully.png";
import Unmudl from "assets/work/unmudl.png"
import Centrefy from "assets/work/centrefy.png"
import UPark from "assets/work/uPark.jpg";

// assets
import { backgroundColors } from "constants/index";

const Work = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");

  const projects = [
    {
      name: "fetefully",
      img: Fetefully,
      title: "Fetefully",
    },
    {
      name: "unmudl",
      img: Unmudl,
      title: "Unmudl",
    },
    {
      name: "centrefy",
      img: Centrefy,
      title: "Centrefy",
    },
    {
      name: "uPark",
      img: UPark,
      title: "uPark",
    },
 
  ];

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <title>Work</title>
      </Helmet>
      <div
        style={{
          background: backgroundColors.work.bgColor,
        }}
        className="min-h-screen"
      >
        <Navbar
          overlayColor={backgroundColors.work.bgColor}
          align="items-center"
        />
        <div className="grid md:grid-cols-2 md:grid-rows-2 lg:grid-cols-2 lg:grid-rows-2 gap-0 lg:h-v-9/10">
          {projects.map((project) => (
            <div
              className="md:col-span-1 md:row-span-1 img-container"
              style={{ flexGrow: 1 }}
              onClick={() => {
                setIsModalOpen(true);
                setProjectName(project.name);
              }}
            >
              <img
                className="img fit-img"
                alt={project.title}
                title={project.title}
                src={project.img}
              ></img>
              <p className="project-name">{project.title}</p>
            </div>
          ))}
        </div>
        {isModalOpen && (
          <Modal
            isOpen
            onClose={() => setIsModalOpen(false)}
            projectName={projectName}
          />
        )}
      </div>
    </>
  );
};

export default Loader(Work, backgroundColors.work.bgColor);
