import { useRef, useEffect } from "react";
import "./index.scss";
import emailjs from "@emailjs/browser";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

// components
import Loader from "components/common/loadingPage";
import Navbar from "components/common/navbar";
import OutlinedButton from "components/common/outlineButton";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faGithub,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

// constants
import { backgroundColors } from "constants/index";

const Contact = () => {
  const formRef = useRef<HTMLFormElement>(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    subject: Yup.string().required(),
    message: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    onSubmit: async () => {
      if (formRef.current === null) return;
      toast.dismiss();
      await toast.promise(
        emailjs.sendForm(
          process.env.REACT_APP_EMAIL_JS_SERVICE_ID || "",
          process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID || "",
          formRef.current,
          process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
        ),
        {
          pending: "Please wait...",
          success: "Message sent successfully",
          error: "Something went wrong",
        }
      );
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <div
        style={{
          background: backgroundColors.contact.bgColor,
        }}
        className="min-h-screen"
      >
        <Navbar overlayColor={backgroundColors.contact.bgColor} />
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col min-h-screen-1/3 justify-center items-center basis-2/4 md:h-v-9/10">
            <h6
              style={{ fontSize: "1.4vmax" }}
              className="font-open-sans text-white"
            >
              Liked my work?
            </h6>
            <h2
              style={{ fontSize: "2.4vmax" }}
              className="font-poppins font-medium text-white"
            >
              Contact Me Here!
            </h2>
            <div className="icons-container flex justify-between items-center mt-4">
              <FontAwesomeIcon
                className="icon"
                icon={faWhatsapp}
                onClick={() =>
                  window.open(
                    "https://web.whatsapp.com/send?phone=+923135696997"
                  )
                }
              />
              <FontAwesomeIcon
                className="icon"
                icon={faEnvelope}
                onClick={() => window.open("mailto:hassanalimalek9@gmail.com")}
              />
              <FontAwesomeIcon
                className="icon"
                icon={faLinkedin}
                onClick={() =>
                  window.open("https://www.linkedin.com/in/hassan-ali-70b30a204/")
                }
              />
              <FontAwesomeIcon
                className="icon"
                icon={faGithub}
                onClick={() => window.open("https://github.com/hassanalimalek")}
              />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center basis-2/4 md:h-v-9/10">
            <form
              ref={formRef}
              className="flex flex-col w-9/12 py-12"
              onSubmit={formik.handleSubmit}
            >
              <div className="flex flex-col lg:flex-row justify-between">
                <div className="w-full lg:mr-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    className="input w-full font-open-sans"
                    style={{
                      borderBottom:
                        formik.touched.name && formik.errors?.name
                          ? "2px solid red"
                          : "",
                    }}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="w-full mt-6 lg:ml-2 lg:mt-0">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    className="input w-full font-open-sans"
                    style={{
                      borderBottom:
                        formik.touched.email && formik.errors?.email
                          ? "2px solid red"
                          : "",
                    }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="mt-6">
                <input
                  id="subject"
                  name="subject"
                  type="text"
                  placeholder="Subject"
                  className="input w-full font-open-sans"
                  style={{
                    borderBottom:
                      formik.touched.subject && formik.errors?.subject
                        ? "2px solid red"
                        : "",
                  }}
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="mt-6">
                <textarea
                  id="message"
                  name="message"
                  placeholder="Message"
                  className="text-area w-full font-open-sans"
                  style={{
                    borderBottom:
                      formik.touched.message && formik.errors?.message
                        ? "2px solid red"
                        : "",
                  }}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="flex justify-end mt-6">
                <OutlinedButton type="submit" title="Send Message" />
              </div>
              <ToastContainer
                toastClassName="toast"
                position="bottom-right"
                hideProgressBar
                closeButton={false}
                autoClose={5000}
                transition={Zoom}
                theme="colored"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader(Contact, backgroundColors.contact.bgColor);
