// assets
import Ali from "assets/images/aliProfile.jpeg"
import Taha from "assets/images/tahaProfile.jpeg"
import AvatarProfile from 'assets/images/avatarProfile.jpeg'
import Fetefully from "assets/work/fetefully.png"
import Unmudl from "assets/work/unmudl.png"
import Centrefy from "assets/work/centrefy.png"
import UPark from "assets/work/uPark.jpg"


export const backgroundColors = {
  home: { bgColor: "linear-gradient(180deg, #1B1425 74.5%, #6B2120 146.17%)", navColor: "#1B1425" },
  about: { bgColor: "linear-gradient(180deg, #1B1425 74.5%, #6B2120 146.17%)", navColor: "#1B1425" },
  skills: { bgColor: "linear-gradient(270deg, #1B1425 29.79%, #6B2120 112.5%)", navColor: "#1B1425" },
  work: { bgColor: "linear-gradient(116.17deg, #1B1425 11.08%, #311824 49.37%, #6B2120 100.87%", navColor: "#1B1425" },
  testimonials: { bgColor: "linear-gradient(360deg, #1B1425 -0.86%, #6B2120 100%)", navColor: "#6B2120" },
  contact: { bgColor: "linear-gradient(180deg, #1B1425 74.5%, #6B2120 146.17%)", navColor: "#1B1425" }
}

export const navbarLink = [
  { name: 'Home', link: "/" },
  { name: 'About', link: "/about"},
  { name: 'Skills', link: "/skills"  },
  { name: 'Work', link: "/work" },
  { name: 'Testimonials', link: "/testimonials" },
  { name: "Contact", link: "/contact" },
  { name: "Resume", isButton: true }
]

export const skills = [
  'Redux',
  'Firebase',
  'Node',
  "Javascript",
  "Typescript",
  'CSS',
  "SCSS",
  "Next JS",
  "HTML",
  "REST",
  "GraphQL",
  'React JS',
  "JEST",
  "Webpack",
  "CI/CD",
  "Git",
  "React Native",
  "i18n",
  "Scrum",
  "Tailwind CSS",
  "Netlify",
  "Heroku",
  "Stripe"
]

export const skillsProgress = [
  { name: 'Front-end', progress: 85 },
  { name: 'Back-end', progress: 40 },
  { name: 'Testing', progress: 45 },
  { name: 'Dev-ops', progress: 20 }
]

export const experience = [
  { name: "Turing Technologies", tenure: "May 2022 - Present", location: "Islamabad, Pakistan", role: "Software Engineer II" },
  { name: "Infinity Bits", tenure: "March 2021 - April 2022", location: "Islamabad, Pakistan", role: 'React JS Developer' },

]

export const testimonialsInfo = [
  {
    name: "Taha Khalid",
    role: "Tech Lead - Turing Technologies",
    img: Taha,
    desc: `I confidently recommend Hassan, an exceptional frontend developer skilled in ReactJS and Next.js, whom I led as a Tech Lead. His invaluable contributions significantly enhanced our project's success. Hassan consistently demonstrated a profound understanding of frontend development, delivering innovative solutions that improved our product's quality. His creativity and resourcefulness in overcoming challenges were evident throughout our collaboration.`,
  },
  {
    name: "Ali Shah",
    role: "Co-Founder - Infinity Bits",
    img: Ali,
    desc: `Hassan joined InfinityBits as a fresh graduate and added value to the team during his 1-year stand at InfinityBits. Resources like Hassan are quite rare to be found, as his sincerity with the work assigned made him a valuable resource in a short span of time. It was a pleasure working with Hassan and I highly recommend him because this man is a team player!
    Wish you good luck in your future endeavors :)`,
  },
  {
    name: "Mehwish Asif",
    role: "Associate Tech Lead - Turing Technologies",
    img: AvatarProfile,
    desc: `I've worked closely with Hassan Ali at Turing Technologies for almost two years, and I've seen his exceptional growth and skills. Hassan consistently delivers high-quality results independently and contributes effectively to team projects. His strong communication skills have been valuable, and he readily incorporates feedback. As his mentor, I'm proud of his performance under my guidance. I confidently recommend Hassan as a skilled web developer, excelling both independently and collaboratively.`,
  },
  
];

export const projectsDetails = {
  unmudl: {
    name: 'Unmudl',
    techStack: [
      "Next JS",
      "Antd",
      "Styled Components",
      "Stripe",
      "REST API",
      "Redux",
      "MonoRepo"
    ],
    description: `Unmudl's mission is to provide learners, particularly working learners, with skill paths
    to learning and employment, while slashing time, cost, and uncertainty.
    `,
    link:"https://unmudl.com/",
    image: Unmudl
  },
  fetefully: {
    name: 'Fetefully',
    techStack: [
      "Next JS",
      "Graphql",
      "Firebase",
      "Antd",
      "Stripe",
      "REST API",
      "MonoRepo",
      "Redux",
    
    ],
    description: `Fetefully offers one-on-one consultations with the world's top wedding planners.
    Meet virtually with a professional wedding planner who can help you on planning
    your dream wedding.
    `,
    link:"https://www.fetefully.com/",
    image: Fetefully
  },
  centrefy: {
    name: "Centrefy",
    techStack: [
      "React",
      "Redux",
      "Material UI",
      "i18",
      "Sockets IO",
      "Rest API",
      "GIT",
      "Social Login",

    ],
    description: `A holistic solution that empowers businesses to improve quality of care with innovative technology. 
    Proactive health companion, based on AI & machine vision technologies, helps enterprises to fulfill their health goals while addressing customer needs.
    `,
    image: Centrefy,
  },
  uPark: {
    name: 'uPark',
    techStack: [
      "React Native Expo",
      "Redux Toolkit",
      "React Native Reanimated",
      "i18next",
      "Firebase Push notification",
      "Redash",
      "Stripe",
      "Geolocation",
      "Google Places",
      "REST API",
    ],
    description: `<p>A mobile parking app designed for regions with a high density of cars, 
    aiming to enhance visibility and ensure parking availability throughout Europe.</p>
    <br />
    <p>I took charge of the creation of the 2nd version of a mobile app. Users have the ability to 
    search for available parking spaces that are equipped with IoT technology to provide real-time 
    occupancy updates. Through the use of a spinner, users can customize their parking duration, and the 
    fare is instantly calculated based on their selected time.
    <p />
    `,
    image: UPark
  },

}